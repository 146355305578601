import { action, thunk } from 'easy-peasy'
import authConfig from 'configs/auth'
import { getCookie } from 'helpers/cookie'
import IPhoneNumberVerificationModel from 'stores/domains/User/models/PhoneNumber/interface'
import { getSessionStorage } from 'helpers/session-storage'

const PhoneNumberVerificationModel: IPhoneNumberVerificationModel = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  errorCode: '',
  showPhoneNumberDrawer: false,
  showAreaCodeDrawer: false,
  phoneNumberVerification: {
    phone: '',
    verified: false,
  },

  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  resetPhoneNumberVerification: action((state) => {
    state.isLoading = false
    state.isError = false
    state.showPhoneNumberDrawer = false
    state.showPhoneNumberDrawer = false
    state.phoneNumberVerification = {
      phone: '',
      verified: false,
    }
    state.errorMessage = ''
  }),
  setShowPhoneNumberDrawer: action((state, payload) => {
    state.showPhoneNumberDrawer = payload
  }),
  setShowAreaCodeDrawer: action((state, payload) => {
    state.showAreaCodeDrawer = payload
  }),
  setPhoneNumberVerification: action((state, payload) => {
    state.phoneNumberVerification = payload
  }),
  sendVerificationCode: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setLoading(true)
        actions.clearError()
        const { apiClient } = injections
        const url = payload?.email
          ? `/authentication/v1/otp/send`
          : `/authentication/v1/phone/otp/send`
        const response = await apiClient({
          url,
          method: 'POST',
          token: getCookie(authConfig.idTokenName),
          data: { ...payload, ip: getSessionStorage('ip') },
        })
        actions.setLoading(false)
        return response
      } catch (err) {
        let errMessage =
          err?.response?.data?.message ||
          'key_something_wrong_try_again'

        actions.error({
          message: errMessage,
          code: err?.response?.data?.code || '',
        })

        if (err?.response?.data?.code === 'TOO_MANY_REQUESTS') {
          const duration =
            err.response.data.data.retry_in_secs || 'beberapa'
          errMessage = `Mohon tunggu ${duration} detik dan silakan coba masukkan kembali ${
            payload?.email ? 'email' : 'nomor HP'
          } kamu`
        } else if (
          err?.response?.data?.code === 'EMAIL_ALREADY_EXIST'
        ) {
          errMessage = 'emailInUseErrorMessage'
        } else if (
          err?.response?.data?.code ===
          'FRAUD_USER_PHONE_NUMBER_DETECTED'
        ) {
          errMessage = 'fraudPhoneDetectErrorMessage'
        } else if (
          err?.response?.data?.code === 'FRAUD_USER_EMAIL_DETECTED'
        ) {
          errMessage = 'fraudEmailDetectErrorMessage'
        }
        throw new Error(errMessage)
      }
    },
  ),
  error: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload.message
    state.errorCode = payload.code
  }),
  clearError: action((state) => {
    state.isError = false
    state.errorMessage = ''
    state.errorCode = ''
  }),
  userPhone: { codeArea: '+62', phone: '' },
  setUserPhone: action((state, payload) => {
    state.userPhone = payload
  }),
  clearUserPhone: action((state) => {
    state.userPhone = {
      codeArea: '+62',
      phone: '',
    }
  }),
  currentTab: 0,
  setCurrentTab: action((state, payload) => {
    state.currentTab = payload
  }),
}

export default PhoneNumberVerificationModel
