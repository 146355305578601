import { action, thunk } from 'easy-peasy'
import ICreateSessionModel from 'stores/domains/Creator/CreateSession/interface'
import authConfig from 'configs/auth'
import { getCookie } from 'helpers/cookie'
import createSessionConfig from 'configs/create-sessions'
import { getDateTimeUTC } from 'helpers/date-time'
const CreatorProfileModel: ICreateSessionModel = {
  isLoading: false,
  isError: false,
  draft: {
    step: 'type',
    title: '',
    type: '',
    category: 'public',
    price: createSessionConfig.minSessionPrice,
    description: '',
    slots: 1,
    startTime: getDateTimeUTC(
      new Date(new Date().getTime() + 7 * 60 * 1000).toISOString(), // Add 7 minutes from normal time
    ),
    cover: '',
    duration: 0,
    exist: false,
    priceMobile: 0,
    priceWeb: 0,
    hasWatermark: false,
  },
  conversionRate: {
    webRate: 0,
    mobileRate: 0,
  },
  priceDetail: null,
  priceEstimation: null,
  errorMessage: '',
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setSessionDraft: action((state, payload) => {
    if (state.draft.exist === false) {
      state.draft = { ...payload, ...{ exist: true } }
    } else {
      state.draft = payload
    }
  }),
  setCreateSessionStep: action((state, payload) => {
    state.draft.step = payload
  }),
  setConversionRate: action((state, payload) => {
    state.conversionRate = payload
  }),
  setPriceDetail: action((state, payload) => {
    state.priceDetail = payload
  }),
  setIncomeEstimation: action((state, payload) => {
    state.priceEstimation = payload
  }),
  resetSessionDraft: action((state) => {
    state.draft = {
      title: '',
      type: '',
      category: 'public',
      price: createSessionConfig.minSessionPrice,
      description: '',
      slots: 1,
      startTime: getDateTimeUTC(
        new Date(new Date().getTime() + 7 * 60 * 1000).toISOString(), // Add 7 minutes from normal time
      ),
      cover: '',
      duration: 0,
      exist: false,
      step: 'type',
      priceMobile: 0,
      priceWeb: 0,
      hasWatermark: false,
    }
  }),

  getConversionRate: thunk(
    async (actions, _payload, { injections }) => {
      try {
        actions.setLoading(true)
        let idToken = getCookie(authConfig.idTokenName)
        const { apiClient } = injections
        const response = await apiClient({
          url: '/payment/v1/price/estimation',
          method: 'GET',
          token: idToken,
        })
        if (response?.data?.code === 'SUCCESS') {
          const webPrice = response.data.data.find(
            (rate) => rate.platform === 'WEB',
          ).price
          const mobilePrice = response.data.data.find(
            (rate) => rate.platform === 'APP',
          ).price
          actions.setConversionRate({
            webRate: webPrice,
            mobileRate: mobilePrice,
          })
        }
        actions.setLoading(false)
      } catch (error) {
        actions.setError(
          error?.response?.data?.message
            ? error.response.data.message
            : 'Ups, terjadi kesalahan',
        )
      }
    },
  ),
  getPriceDetail: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setLoading(true)
      const { apiClient } = injections
      const response = await apiClient({
        url: `/payment/v1/price/tier/${payload.params.tierId}/detail`,
        method: 'GET',
        token: getCookie(authConfig.idTokenName),
      })
      actions.setPriceDetail(response.data.data)
      actions.setLoading(false)
    } catch (error) {
      actions.setLoading(false)
    }
  }),
  getIncomeEstimation: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: `/wallet/v1/income/estimation/${payload.params.tierId}/SESSION`,
          method: 'GET',
          token: getCookie(authConfig.idTokenName),
        })
        actions.setIncomeEstimation(response.data.data)
        actions.setLoading(false)
      } catch (error) {
        actions.setLoading(false)
      }
    },
  ),
  setError: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload
  }),
}

export default CreatorProfileModel
