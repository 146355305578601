import {
  ICreateAccountPayload,
  ISignInEmailPayload,
  ISignInPhonePayload,
  ISignUpPayload,
} from 'stores/domains/User/Auth/interface'

import { getIdToken } from 'helpers/auth'
import apiClient from 'helpers/api-client'
import { getWithExpiry } from 'helpers/local-storage'

import authConfig from 'configs/auth'
import { getSessionStorage } from 'helpers/session-storage'

export const createAccountByPhone = async (
  payload: ICreateAccountPayload,
) => {
  return await apiClient({
    url: `/authentication/guest/v1/phone/account`,
    method: 'POST',
    data: {
      ...payload,
      ip: getSessionStorage('ip'),
    },
  })
}

export const signInForEmail = async (
  payload: ISignInEmailPayload,
) => {
  return await apiClient({
    url: `/authentication/guest/v1/sign-in`,
    method: 'POST',
    data: payload,
  })
}

export const signInForPhone = async (
  payload: ISignInPhonePayload,
) => {
  return await apiClient({
    url: `/authentication/guest/v1/phone/sign-in`,
    method: 'POST',
    data: payload,
  })
}

export const signUp = async (payload: ISignUpPayload) => {
  return await apiClient({
    url: `/authentication/guest/v1/sign-up`,
    method: 'POST',
    data: {
      ...payload,
    },
  })
}

export const signOut = async () => {
  const refreshToken = getWithExpiry(authConfig.refreshTokenName)

  return await apiClient({
    url: `/authentication/v1/logout`,
    method: 'POST',
    token: getIdToken(),
    data: {
      refresh_token: refreshToken,
    },
  })
}

export const postRefreshToken = async () => {
  const refreshToken = getWithExpiry(authConfig.refreshTokenName)

  return await apiClient({
    url: `/authentication/guest/v1/refresh-token`,
    method: 'POST',
    data: {
      refresh_token: refreshToken,
    },
    token: getIdToken(),
  })
}

export const signInSSO = async (provider) => {
  return await apiClient({
    url: `/authentication/guest/v1/login-sso/${provider}`,
    method: 'GET',
  })
}

export const callbackSSO = async (provider) => {
  return await apiClient({
    url: `/authentication/guest/v1/callback/${provider}`,
    method: 'GET',
  })
}

export const getSSOToken = async (sessionId) => {
  return await apiClient({
    url: `/authentication/guest/v1/get-sso/${sessionId}`,
    method: 'GET',
  })
}

export const changePassword = async (
  oldPassword: string,
  newPassword: string,
) => {
  return await apiClient({
    url: `/authentication/v1/change-password`,
    method: 'POST',
    data: {
      previous_password: oldPassword,
      new_password: newPassword,
      ip: getSessionStorage('ip'),
    },
    token: getIdToken(),
  })
}
