import { useState, useEffect, MouseEventHandler } from 'react'
import { trackEvent, getStateFromSource } from 'helpers/analytics'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import {
  RadioGroup,
  FormControl,
  Radio,
  FormControlLabel,
  useTheme,
} from '@mui/material'
import _get from 'lodash/get'

import Attention from 'components/commons/Attention'
import StateImage from 'components/commons/StateImage'
import ModalDrawer from 'components/domains/User/ModalDrawer'
import Button from 'components/commons/Button'
import Stack from 'components/commons/Stack'
import Box from 'components/commons/Box'
import Typography from 'components/commons/Typography'

import { IProfileData } from 'stores/domains/User/UserProfile'

import apiClient from 'helpers/api-client'
import { getIdToken } from 'helpers/auth'
import { getReportOptionList } from 'services/rating'
import trackers from 'src/trackers'
import { StyledTextField } from 'components/commons/Form/HookForm/RHFTextField'
import ActionButton from 'components/commons/Button/Action'

export type ReportType =
  | 'session'
  | 'creator'
  | 'supporter'
  | 'product'
  | 'event'
  | 'community'
  | 'direct-message'

interface IErrorViewParams {
  failedSubmit: boolean
  reportType: ReportType
}

function AnotherReportReason({
  textValue = '',
  onTextValueChange = () => {},
  onSubmit = () => {},
  reportType,
}: {
  textValue: string
  onTextValueChange: Function
  onSubmit: Function | MouseEventHandler<HTMLButtonElement> | any
  reportType?: ReportType
}) {
  const { t } = useTranslation()
  return (
    <Stack spacing={1}>
      <StyledTextField
        multiline
        fullWidth
        value={textValue}
        placeholder={
          reportType === 'community'
            ? t('key_write_the_details_you_want_to_report')
            : t('reportOtherReasonHintText')
        }
        onChange={(evt) => {
          const newVal = evt.target.value.replace(
            /[^\w|.|\-| |,|?|!]/gi,
            '',
          )
          if (newVal.length > 300) return
          onTextValueChange(newVal)
        }}
        rows={3}
      />
      <Box>
        <ActionButton
          id={`b-commons-report-other-submit`}
          disabled={textValue.length < 5 || textValue.length > 300}
          fullWidth
          onClick={onSubmit}
        >
          {t('sendReport')}
        </ActionButton>
      </Box>
    </Stack>
  )
}

function ErrorView({
  failedSubmit = false,
  reportType = 'session',
}: IErrorViewParams) {
  const { t } = useTranslation()

  let errorTitle = t('failedToDisplayPage')
  let errorDescription = t('failedToDisplayPageDesc')

  if (failedSubmit) {
    switch (reportType) {
      case 'session':
        errorTitle = 'Gagal melaporkan sesi'
        errorDescription = t(
          'key_an_error_occurred_in_reporting_the_session_please_refresh_to_try_again',
        )
        break
      case 'product':
        errorTitle = 'Gagal melaporkan konten'
        errorDescription = t(
          'key_an_error_occurred_in_reporting_content_please_refresh_to_try_again',
        )
        break
      case 'event':
        errorTitle = t('cannotReportEvent')
        errorDescription = t(
          'key_an_error_occurred_in_reporting_the_event_please_refresh_to_try_again',
        )
        break
      case 'creator':
      case 'supporter':
        errorTitle = t('failedToReportAccount')
        errorDescription = t(
          'key_an_error_occurred_in_reporting_the_account_please_refresh_to_try_again',
        )
        break
      default:
        errorTitle = t('failedToDisplayPage')
        errorDescription = t('failedToDisplayPageDesc')
        break
    }
  }

  return (
    <Attention
      padding="20px"
      title={errorTitle}
      desc={errorDescription}
      textBtnFirst={t('refresh')}
      handleRedirectBtnFirst={() => {
        window.location.reload()
      }}
    >
      <StateImage type="error" />
    </Attention>
  )
}

function DoneView({
  reportType = 'session',
  onSubmit = () => {},
  pageName = '',
  setIsDone,
}: {
  reportType: ReportType
  onSubmit: Function
  pageName: string | string[]
  setIsDone?: Function
}) {
  const { t } = useTranslation()
  const generateMessage = () => {
    switch (reportType) {
      case 'direct-message':
        return t('key_thank_you_for_reporting_user')
      case 'community':
      default:
        return t('key_report_successfully_sent')
    }
  }

  return (
    <Box>
      <Stack
        spacing={1}
        alignItems={'center'}
        sx={{ textAlign: 'center' }}
      >
        <Box sx={{ marginBottom: 3 }}>
          <StateImage type="share-content" />
        </Box>
        <Typography
          sx={(theme) => ({
            ...theme.typography.h4,
            color: theme.palette.text.primary,
            [theme.breakpoints.up('sm')]: {
              ...theme.typography.h4Desk,
            },
          })}
        >
          {generateMessage()}
        </Typography>
        <Typography
          sx={(theme) => ({
            ...theme.typography.normalRegular,
            color: theme.palette.text.secondary,
            [theme.breakpoints.up('sm')]: {
              maxWidth: '343px',
            },
          })}
        >
          {reportType === 'community'
            ? t('key_thank_you_for_your_report')
            : t('successReportSubtitle')}
        </Typography>
        <Box sx={{ marginTop: '40px !important', width: '100%' }}>
          <ActionButton
            id={`b-common-report-drawer-submit-another`}
            fullWidth
            onClick={() => {
              if (reportType === 'direct-message') setIsDone(false)
              trackEvent('close_report_success_page', {
                state_name: pageName,
                report_type: reportType,
              })

              onSubmit()
            }}
          >
            {t('successSubmitReportButtonTitle')}
          </ActionButton>
        </Box>
      </Stack>
    </Box>
  )
}

interface ITrackerProperties {
  trackerData: any
  trackerParams: any
}

interface IReportDrawerProps {
  onClose?: Function
  showDrawer: boolean
  reportType: ReportType
  sessionId?: string | string[]
  productId?: string | string[]
  communityId?: string | string[]
  trackerProperties?: Partial<ITrackerProperties>
  profileData?: IProfileData
  peerId?: string
  message?: string
}

// main component
export default function ReportDrawer({
  onClose = () => {},
  showDrawer = false,
  reportType,
  sessionId = '',
  productId = '',
  communityId = '',
  trackerProperties = {
    trackerData: {},
    trackerParams: {},
  },
  profileData,
  peerId = '',
  message = '',
}: IReportDrawerProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { pathname } = useRouter()

  const [isLoading, setIsLoading] = useState(false)
  const [reportReasonList, setReportReasonList] = useState(null)
  const [selectedReportReason, setSelectedReportReason] =
    useState(null)
  const [isOtherReason, setIsOtherReason] = useState(false)
  const [otherReason, setOtherReason] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isErrorSubmit, setIsErrorSubmit] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const [submittedReportComment, setSubmittedReportComment] =
    useState('')

  const {
    creator_id,
    creator_user_id,
    name,
    category_name,
    category,
  } = _get(profileData, 'creator', {})

  const reportCreatorDataProps = {
    state_name: getStateFromSource(pathname),
    creator_id: creator_id,
    creator_user_id: creator_user_id,
    creator_name: name,
    creator_category: category_name,
    creator_category_id: category,
  }

  // submit report
  async function submitReport({
    comment = '',
  }: {
    comment?: string
  }) {
    if (!isSubmitting) {
      try {
        setIsSubmitting(true)

        let endpointUrl = ''

        switch (reportType) {
          case 'direct-message':
            endpointUrl = `/rating/v1/report/${peerId}/direct-message`
            break
          case 'session':
            endpointUrl = `/rating/v1/report/${sessionId}/session`
            break
          case 'creator':
            endpointUrl = `/rating/v1/report/${creator_id}/creator`
            break
          case 'supporter':
            endpointUrl = `/rating/v1/report/${profileData.user_id}/supporter`
            break
          case 'community':
            endpointUrl = `/rating/v1/report/${communityId}/community`
            break
          case 'event':
          case 'product':
            endpointUrl = `/rating/v1/report/${productId}/product`
            break
        }

        await apiClient({
          url: endpointUrl,
          method: 'POST',
          data: {
            category_id: reportReasonList[selectedReportReason].id,
            comment: comment,
            message: reportType === 'direct-message' && message,
          },
          token: getIdToken(),
        })

        switch (reportType) {
          case 'community':
            trackers.community(
              'submit_report_community_reason',
              trackerProperties?.trackerData,
              {
                ...trackerProperties?.trackerParams,
                report_reason:
                  comment ||
                  reportReasonList[selectedReportReason].value,
              },
            )
            break
          case 'creator':
            trackers.user(
              'submit_report_creator_reason',
              trackerProperties?.trackerData,
              {
                ...reportCreatorDataProps,
                modal_name: 'REPORT_CREATOR',
                report_reason:
                  comment ||
                  reportReasonList[selectedReportReason].value,
                is_creator_own_profile: false,
              },
            )
            break
        }

        setIsDone(true)
      } catch (err) {
        setIsErrorSubmit(true)
        setIsError(true)
      } finally {
        setSubmittedReportComment(comment)
        setIsSubmitting(false)
        setIsOtherReason(false)
        setOtherReason('')
      }
    }
  }

  async function compInit() {
    try {
      setIsLoading(true)

      const optionList = await getReportOptionList(reportType)

      if (optionList.data.data.length > 0) {
        setReportReasonList(optionList.data.data)
      }
    } catch (err) {
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (isDone && !isSubmitting) {
      const report_reason =
        submittedReportComment ||
        reportReasonList[selectedReportReason].value

      switch (reportType) {
        case 'community':
          trackers.community(
            'report_community_completed',
            trackerProperties?.trackerData,
            {
              ...trackerProperties?.trackerParams,
              report_reason,
            },
          )
          break
        case 'creator':
          trackEvent('report_creator_completed', {
            ...reportCreatorDataProps,
            modal_name: 'REPORT_CREATOR',
            report_reason: report_reason,
            is_creator_own_profile: false,
          })
          break
      }
    }
  }, [isDone, isSubmitting])

  useEffect(() => {
    compInit()
  }, [])

  function getDrawerTitle() {
    if (isDone) {
      return ''
    }
    if (isOtherReason) {
      return t('other')
    }
    switch (reportType) {
      case 'session':
        return t('reportSessionBottomSheetTitle')
      case 'event':
        return t('key_why_are_you_reporting_this_event')
      case 'product':
        return t('whyYouReportThisContent')
      case 'community':
        return t('key_what_would_you_like_to_report')
      case 'creator':
      case 'supporter':
      default:
        return t('reportAccountBottomSheetTitle')
    }
  }

  const renderSubtitle = () => {
    switch (reportType) {
      case 'community':
        return t('key_report_are_anonymous')
      case 'creator':
      case 'direct-message':
        return t('reportAccountBottomSheetSubtitle')
      case 'event':
      case 'product':
      case 'session':
      default:
        return t('yourReportIsAnonymous')
    }
  }

  const renderButtonTitle = () => {
    switch (reportType) {
      case 'session':
        return t('reportSession')
      case 'creator':
      case 'direct-message':
        return t('reportCreator')
      case 'event':
        return t('reportEventButton')
      case 'product':
        return t('reportContent')
      case 'community':
      default:
        return t('sendReport')
    }
  }

  return (
    <ModalDrawer
      title={getDrawerTitle()}
      isOpen={showDrawer}
      onClose={() => {
        if (reportType === 'direct-message') setIsDone(false)
        if (isOtherReason) {
          setIsOtherReason(false)
          onClose()
          return
        }

        if (isError) {
          trackEvent('close_report_failed_page', {
            state_name: getStateFromSource(pathname),
            report_type: reportType,
          })
        }

        if (reportType === 'creator') {
          trackEvent('close_report_failed_page', {
            ...reportCreatorDataProps,
            report_reason: selectedReportReason
              ? reportReasonList[selectedReportReason].value
              : '',
          })
        }

        onClose()
      }}
      sxDialogContainer={{
        [theme.breakpoints.up('sm')]: {
          '& .MuiDialogContent-root': {
            padding: '0px',
          },
        },
      }}
      sxDrawerContainer={{
        margin: 0,
        paddingTop: '16px',
      }}
    >
      <Stack
        id={`c-common-report-drawer-wrapper`}
        spacing={1}
        paddingBottom={{ xs: '16px', md: '0px' }}
      >
        {isError && !isDone && (
          <ErrorView
            failedSubmit={isErrorSubmit}
            reportType={reportType}
          />
        )}
        {!isError && isDone && (
          <DoneView
            reportType={reportType}
            onSubmit={() => {
              onClose()
            }}
            pageName={getStateFromSource(pathname)}
            setIsDone={setIsDone}
          />
        )}
        {!isError && !isDone && !isOtherReason && (
          <Stack spacing={1}>
            {
              <Typography
                sx={(theme) => ({
                  ...theme.typography.normalRegular,
                  textAlign: 'left',
                })}
              >
                {renderSubtitle()}
              </Typography>
            }
            <Box>
              {!isLoading &&
                reportReasonList &&
                reportReasonList.length > 0 && (
                  <FormControl
                    sx={{
                      width: '100%',
                      overflow: 'hidden',
                    }}
                  >
                    <RadioGroup
                      name="report-reason"
                      value={selectedReportReason}
                      onChange={(evt) => {
                        setSelectedReportReason(
                          Number(evt.target.value),
                        )
                      }}
                      sx={{
                        '& > label': {
                          position: 'relative',
                          marginLeft: '0',
                        },
                      }}
                    >
                      {reportReasonList.map((reason, reasonIndex) => {
                        return (
                          <FormControlLabel
                            key={`report-reason-item-${reason.id}`}
                            id={`c-common-report-drawer-option-${reasonIndex}`}
                            control={
                              <Radio sx={{ paddingLeft: 0 }} />
                            }
                            value={reasonIndex}
                            label={`${reason.value}`}
                            labelPlacement="start"
                            sx={(theme) => ({
                              justifyContent: 'space-between',
                              borderBottom: `1px solid ${theme.palette.border.minimal}`,
                              '& .MuiFormControlLabel-label': {
                                color: theme.palette.text.primary,
                                ...theme.typography.normalRegular,
                              },
                            })}
                          />
                        )
                      })}
                    </RadioGroup>
                  </FormControl>
                )}
            </Box>
            <Box>
              <Button
                id={`b-common-report-drawer-submit`}
                disabled={
                  selectedReportReason === null || isSubmitting
                }
                fullWidth
                sx={{
                  paddingX: 3,
                  borderRadius: '8px',
                  marginTop: '24px !important',
                }}
                onClick={async () => {
                  // open other reason input text box
                  if (
                    reportReasonList[
                      selectedReportReason
                    ].value.toLowerCase() === 'lainnya'
                  ) {
                    setIsOtherReason(true)
                    return
                  }

                  submitReport({ comment: '' })
                }}
              >
                {renderButtonTitle()}
              </Button>
            </Box>
          </Stack>
        )}
        {!isError && !isDone && isOtherReason && (
          <AnotherReportReason
            textValue={otherReason}
            onTextValueChange={(newVal) => {
              setOtherReason(newVal)
            }}
            onSubmit={async () => {
              submitReport({ comment: otherReason })
            }}
            reportType={reportType}
          />
        )}
      </Stack>
    </ModalDrawer>
  )
}
