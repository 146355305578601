import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import {
  debounce,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Stack from 'components/commons/Stack'
import Typography from 'components/commons/Typography'

import BottomDrawer from 'components/commons/BottomDrawer'
import ErrorPage from 'components/commons/ErrorPage'
import { useStoreActions, useStoreState } from 'stores/hooks'
import { getStateFromSource, trackEvent } from 'helpers/analytics'
import { setUserDevice } from 'helpers/user-device'

import CoinPackage from 'components/domains/Wallet/CoinPackage'
import PaymentConfirmationDrawerContent from 'components/domains/Wallet/PaymentConfirmationDrawerContent'
import ExternalPaymentDrawerContent from 'components/domains/Wallet/ExternalPaymentDrawerContent'
import ErrorTopupDrawerContent from 'components/domains/Wallet/ErrorTopupDrawerContent'
import DirectPaymentModal from 'containers/domains/Wallet/DirectPaymentModal'

import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'

//services
import { postTopupRequest, topUpCoinPayment } from 'services/payment'
import { generateShortUuid } from 'helpers/generate/uuid'
import ModalDialog from 'components/commons/Modal/Dialog/v2'

import CloseIcon from '@mui/icons-material/Close'
import Button from 'components/commons/Button'
import Box from 'components/commons/Box'
import StateImage from 'components/commons/StateImage'
import { useTranslation } from 'react-i18next'

const StyledError = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  color: theme.palette.text.primary,
  fontSize: 14,
  margin: '5px 0px',
  alignItems: 'center',
  svg: {
    marginRight: 5,
    color: theme.palette.error.main,
    fontSize: '22px',
  },
}))

const StyledInfo = styled(StyledError)(({ theme }) => ({
  svg: {
    color: theme.palette.info.main,
  },
}))

const ContainerBtn = styled(Stack)({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
})

const StyledContentList = styled(Stack)(({ theme }) => ({
  overflow: 'auto',
  [theme.breakpoints.down('md')]: {
    maxHeight: 'unset',
    overflow: 'unset',
  },
}))

const ActionButtonContainer = styled(Box)({
  marginTop: '24px',
  alignItems: 'center',
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
})

interface ITopupDrawer {
  sessionId?: string
  creatorId?: string
  creatorUserId?: string
  categoryId?: string
  categoryName?: string
  preCheckout?: Function
}

function numberFormatter(num) {
  return new Intl.NumberFormat('id-ID').format(num)
}

function TopUpDrawer({
  sessionId = '',
  creatorId = '',
  creatorUserId = '',
  categoryId = '',
  categoryName = '',
  preCheckout = () => {},
}: ITopupDrawer) {
  const { t } = useTranslation()

  const errorRef = useRef(null)
  const { pathname, query } = useRouter()
  const theme = useTheme()
  const [idempotentKey, setIdempotentKey] = useState('')
  const [isPaymentDrawerShown, setIsPaymentDrawerShown] =
    useState(false)
  const [paymentType, setPaymentType] = useState('')
  const isDesktopResolution = useMediaQuery(
    theme.breakpoints.up('sm'),
  )
  const {
    setTopUpCoinStatus,
    getCoinPackages,
    setShowTopUpModal,
    setShowPaymentConfirmationModal,
    setShowExternalPaymentModal,
    postTopupInquiry,
  } = useStoreActions((actions) => actions.topUp)

  const { getCoinBalance } = useStoreActions(
    (actions) => actions.coinBalance,
  )

  const {
    coinPackages,
    isLoading,
    isError,
    errorMessage,
    statusCode,
    showTopUpModal,
    showPaymentConfirmationModal,
    showExternalPaymentModal,
    totalCoinTopUp,
    totalAmountTopUp,
    topupInquiry,
  } = useStoreState((actions) => actions.topUp)

  const trackerData = {
    pathname,
    query,
    totalCoinTopUp,
  }

  const handleInquiryTopup = () => {
    let payload = []
    let eventProperties = {
      state_name: getStateFromSource(pathname),
      modal_name: getStateFromSource('TopUpCoinDrawer'),
      total_topup_coin: totalCoinTopUp,
      total_topup_amount: totalAmountTopUp,
    }
    if ('' !== sessionId) {
      eventProperties['session_id'] = sessionId
      eventProperties['creator_id'] = creatorId
      eventProperties['creator_user_id'] = creatorUserId
      eventProperties['creator_category_id'] = String(categoryId)
      eventProperties['creator_category'] = categoryName
    }
    coinPackages.coins.forEach((coinPackage, _index) => {
      if (coinPackage.quantity > 0) {
        payload.push({
          coin_id: coinPackage.id,
          qty: coinPackage.quantity,
        })
      }
    })
    trackEvent('select_topup_coins_tier', eventProperties)
    postTopupInquiry(payload)
  }

  const handleSubmitTopup = () => {
    // send user device information
    setUserDevice()
    return postTopupRequest({
      payment_id: topupInquiry?.payment_id,
      payment_method_code: paymentType,
      idempotentKey: idempotentKey,
    })
  }
  const handleCloseTopUpDrawer = () => {
    let eventProperties = {
      state_name: getStateFromSource(pathname),
      modal_name: getStateFromSource('TopUpCoinDrawer'),
      total_topup_coin: totalCoinTopUp,
      total_topup_amount: totalAmountTopUp,
    }
    if ('' !== sessionId) {
      eventProperties['session_id'] = sessionId
      eventProperties['creator_id'] = creatorId
      eventProperties['creator_user_id'] = creatorUserId
      eventProperties['creator_category_id'] = String(categoryId)
      eventProperties['creator_category'] = categoryName
    }
    trackEvent('cancel_topup', eventProperties)
    setShowTopUpModal(false)
  }
  const handleClosePaymentConfirmationDrawer = () => {
    let eventProperties = {
      state_name: getStateFromSource(pathname),
      modal_name: 'TOPUP_PAYMENT_CONFIRMATION',
      total_topup_coin: totalCoinTopUp,
      total_topup_amount: topupInquiry?.sub_total,
    }
    if ('' !== sessionId) {
      eventProperties['session_id'] = sessionId
      eventProperties['creator_id'] = creatorId
      eventProperties['creator_user_id'] = creatorUserId
      eventProperties['creator_category_id'] = String(categoryId)
      eventProperties['creator_category'] = categoryName
    }
    trackEvent('cancel_confirm_topup', eventProperties)
    setShowPaymentConfirmationModal(false)
  }
  const handleCloseExternalPaymentModal = () => {
    setShowExternalPaymentModal(false)
    getCoinBalance()
  }

  const handleSubmitTopUpConfirmation = () => {
    let eventProperties = {
      state_name: getStateFromSource(pathname),
      modal_name: 'TOPUP_PAYMENT_CONFIRMATION',
      total_topup_coin: totalCoinTopUp,
      total_topup_amount: topupInquiry?.sub_total,
    }
    trackEvent('confirm_topup', eventProperties)
    setShowPaymentConfirmationModal(false)
    setIsPaymentDrawerShown(true)
  }

  const handleCloseDirectPayment = () => {
    setIdempotentKey(generateShortUuid())
    setIsPaymentDrawerShown(false)
  }

  useEffect(() => {
    if (showTopUpModal) {
      getCoinPackages()
    }
  }, [showTopUpModal])

  useEffect(() => {
    setIdempotentKey(generateShortUuid())
  }, [])

  useEffect(() => {
    if (totalCoinTopUp > coinPackages.max_total_coin_purchase) {
      errorRef.current.scrollIntoView()
    }
  }, [totalCoinTopUp, coinPackages.max_total_coin_purchase])

  if (isLoading) return null

  const ListCoinContent = () => {
    return (
      <>
        {isError ? (
          statusCode === 500 ? (
            <ErrorPage
              title="Terjadi kesalahan sistem"
              subtitle="Maaf terjadi kesalahan pada sistem saat menampilkan halaman. Silakan coba beberapa saat lagi"
              illustration={<StateImage type="system-error" />}
              onRefresh={getCoinPackages}
              sx={(theme) => ({
                background: theme.palette.background.secondary,
              })}
            />
          ) : (
            <ErrorTopupDrawerContent />
          )
        ) : (
          <>
            {coinPackages.info !== '' && (
              <StyledInfo>
                <InfoIcon />
                <Typography>{coinPackages.info}</Typography>
              </StyledInfo>
            )}
            {totalCoinTopUp >
              coinPackages.max_total_coin_purchase && (
              <StyledError ref={errorRef}>
                <ErrorIcon />
                <Typography>
                  {t('key_max_top_up', {
                    max: numberFormatter(
                      coinPackages.max_total_coin_purchase,
                    ),
                  })}
                </Typography>
              </StyledError>
            )}
            {isError ? (
              <StyledError>
                <ErrorIcon />
                <Typography>{errorMessage}</Typography>
              </StyledError>
            ) : (
              <>
                <StyledContentList>
                  {coinPackages.coins.map((item) => (
                    <CoinPackage
                      key={'coin-package-' + item.id}
                      id={item.id}
                      coin_unit={item.coin_unit}
                      purchase_price={item.purchase_price}
                      original_price={item.original_price}
                      discount={item.disc.amount}
                      currency_label={item.currency_label}
                      quantity={item.quantity}
                      totalCoinTopUp={totalCoinTopUp}
                      max_total_coin_purchase={
                        coinPackages?.max_total_coin_purchase
                      }
                    />
                  ))}
                </StyledContentList>
              </>
            )}
          </>
        )}
      </>
    )
  }

  if (isPaymentDrawerShown) {
    return (
      <DirectPaymentModal
        title={t('paymentConfirmationBottomSheetTitleV2')}
        trackerData={trackerData}
        onClose={() => {
          handleCloseDirectPayment()
          setTopUpCoinStatus('finish')
        }}
        onInit={() => {
          return topUpCoinPayment({
            coins: topupInquiry?.coins,
            idempotentKey,
          })
        }}
        onPayNowClick={() => {
          return handleSubmitTopup()
        }}
        onPaymentClose={() => {
          setIdempotentKey(generateShortUuid())
          getCoinBalance()
          setIsPaymentDrawerShown(false)
          setTopUpCoinStatus('finish')
        }}
        itemType={'coin'}
        idempotentKey={idempotentKey}
        setIdempotentKey={setIdempotentKey}
        useVoucher={false}
        setPaymentType={setPaymentType}
        preCheckout={preCheckout}
      />
    )
  }

  if (isDesktopResolution) {
    return (
      <>
        <ModalDialog
          open={showTopUpModal}
          onClose={handleCloseTopUpDrawer}
          title={t('topUpCoins')}
          closeButtonIcon={
            <CloseIcon sx={{ color: theme.palette.tiptip[500] }} />
          }
          sx={{
            '.MuiDialog-paper': {
              paddingBottom: '27px',
              minWidth: '500px',
            },
            '& .MuiDialogContent-root': {
              padding: 0,
              maxHeight: '550px',
            },
          }}
          actions={
            <ActionButtonContainer>
              <ContainerBtn
                direction="row"
                justifyContent={'center'}
                alignItems="center"
                spacing={2}
              >
                <Button
                  disableElevation
                  fullWidth
                  onClick={debounce(handleInquiryTopup, 1000)}
                  disabled={
                    totalCoinTopUp <= 0 ||
                    totalCoinTopUp >
                      coinPackages.max_total_coin_purchase ||
                    isLoading
                  }
                  id="b-wallet-submit-top-up-coin"
                  sx={{ height: '44px' }}
                >
                  {t('topUpCoinButtonTitle')}
                </Button>
              </ContainerBtn>
            </ActionButtonContainer>
          }
        >
          <ListCoinContent />
        </ModalDialog>
        <ModalDialog
          open={showPaymentConfirmationModal}
          onClose={handleClosePaymentConfirmationDrawer}
          closeButtonIcon={
            <CloseIcon sx={{ color: theme.palette.tiptip[500] }} />
          }
          title={t('paymentConfirmationBottomSheetTitle')}
        >
          <PaymentConfirmationDrawerContent
            onShowPaymentDrawer={() => {
              handleSubmitTopUpConfirmation()
            }}
          />
        </ModalDialog>
      </>
    )
  } else {
    return (
      <>
        {/* Topup Drawer */}
        <BottomDrawer
          title={t('topUpCoins')}
          content={<ListCoinContent />}
          showDrawer={showTopUpModal}
          onCloseAction={handleCloseTopUpDrawer}
          isRequesting={
            totalCoinTopUp <= 0 ||
            totalCoinTopUp > coinPackages.max_total_coin_purchase ||
            isLoading
          }
          id="b-wallet-submit-top-up-coin"
          disablePadding
          closeButtonId="b-wallet-close-top-up-drawer"
          onSubmitAction={debounce(handleInquiryTopup, 1000)}
          submitLabel={t('topUpCoinButtonTitle')}
        />
        {/* Payment Confirmation Drawer */}
        <BottomDrawer
          title="Konfirmasi Pembayaran"
          content={
            <PaymentConfirmationDrawerContent
              onShowPaymentDrawer={() => {
                handleSubmitTopUpConfirmation()
              }}
            />
          }
          showDrawer={showPaymentConfirmationModal}
          onCloseAction={handleClosePaymentConfirmationDrawer}
          hideButtonRequest={true}
          isRequesting={isLoading}
          closeButtonId="b-wallet-close-top-up-confirmation"
          disablePadding
        />
        {/* External Payment Drawer */}
        <BottomDrawer
          content={<ExternalPaymentDrawerContent />}
          showDrawer={showExternalPaymentModal}
          onCloseAction={handleCloseExternalPaymentModal}
          hideButtonRequest={true}
          isRequesting={isLoading}
          closeButtonId="b-wallet-close-external-payment"
        />
      </>
    )
  }
}

export default TopUpDrawer
