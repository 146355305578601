import { getIdToken } from 'helpers/auth'
import apiClient from 'helpers/api-client'
import ampConfig from 'configs/amplify'
import { IActionUserDevice } from 'constants/user'
import { isGuestMode } from 'helpers/cookie'

export const requestForgotPassword = async (email: string) => {
  return await apiClient({
    url: '/user/guest/v1/forgot-password',
    method: 'POST',
    data: {
      username: email,
      client_id: ampConfig.aws_user_pools_web_client_id,
    },
  })
}

export const updateUserDevice = async (
  fcmToken: string,
  action: IActionUserDevice,
) => {
  return await apiClient({
    url: '/user/v1/device',
    method: 'PUT',
    data: {
      fcm_token_id: fcmToken,
      action,
    },
    token: getIdToken(),
  })
}

export const getPhoneVerified = async (token?: string) => {
  if (isGuestMode()) return {}
  const response = await apiClient({
    url: '/user/v1/phone',
    method: 'GET',
    token: token,
  })
  return response?.data
}

export const getCategories = async () => {
  return await apiClient({
    url: '/user/guest/v2/categories',
    method: 'GET',
  })
}

export const getPopularCreator = async (params) => {
  return await apiClient({
    url: '/user/guest/v2/popular',
    method: 'GET',
    params,
  })
}

export const getReferralCodeInfo = async (referral_code: string) => {
  return await apiClient({
    url: isGuestMode()
      ? `/user/guest/v1/referral-code/${referral_code}/info`
      : `/user/v1/referral-code/${referral_code}/info`,
    method: 'GET',
    token: getIdToken(),
  })
}

export const getSupportedCountries = async () => {
  return await apiClient({
    url: '/user/guest/v1/supported-countries',
    method: 'GET',
  })
}
