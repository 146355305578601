import { getLatitudeLongitude } from 'helpers/navigator'
import apiClient from 'helpers/api-client'
import ipstackConfig from 'configs/ipstack'
import { sendLog, setTag } from 'helpers/log'
import { setDefaultProperties } from 'helpers/analytics'
import {
  getSessionStorage,
  setSessionStorage,
} from 'helpers/session-storage'
import isEmpty from 'helpers/is-empty'
import { ClientJS } from 'clientjs/src/client.base'

function setUserDevice(): void {
  interface IUserDevice {
    latitude?: number
    longitude?: number
    ip?: string
  }
  Promise.all([
    getLatitudeLongitude(),
    new Promise((resolve) => {
      apiClient({
        apiHost: ipstackConfig.baseUrl,
        method: 'GET',
        params: {
          access_key: ipstackConfig.accessKey,
        },
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      })
        .then((res) => {
          setSessionStorage('ip', res?.data?.ip)

          setSessionStorage('metaData', {
            timeZone: res?.data?.['time_zone']['id'],
            latitude: res?.data?.['latitude'],
            longitude: res?.data?.['longitude'],
          })
          setTag('isp', res?.data?.connection?.isp || '')
          resolve(res?.data)
        })
        .catch((error) => {
          sendLog(`ERROR(${error?.code}): ${error?.message}`)
        })
    }),
  ])
    .then((res) => {
      const responseLocation: IUserDevice = res[0] // response from navigator.geolocation.currentPosition
      const responseIpAddress: IUserDevice = res[1] // response from ipstack
      // set super property to mixpanel
      setDefaultProperties({
        // default set latitude from navigator, if null will assign from ipstack
        latitude:
          responseLocation?.latitude ||
          responseIpAddress?.latitude ||
          null,
        // default set longitude from navigator, if null will assign from ipstack
        longitude:
          responseLocation?.longitude ||
          responseIpAddress?.longitude ||
          null,
        ip_address: responseIpAddress?.ip || null, // collect IP Address from ipstack
        unique_device_id: getFingerprint(), // fingerprint for device id
      })
    })
    .catch((error) => {
      sendLog(`ERROR(${error?.code}): ${error?.message}`)
    })
}

function getFingerprint(): string {
  const fp = getSessionStorage('fp') // fingerprint that stored on session storage

  if (isEmpty(fp)) {
    const fingerprint = getFingerprintClientJs()
    setSessionStorage('fp', fingerprint)
    return fingerprint
  }

  return fp
}

function getFingerprintClientJs(): string {
  const client = new ClientJS()
  const fingerprint = client.getFingerprint()
  return String(fingerprint)
}

export { setUserDevice, getFingerprint, getFingerprintClientJs }
