import { useState, useEffect } from 'react'
import { CircularProgress, Skeleton } from '@mui/material'
import DirectPaymentDrawerContent from 'components/domains/Wallet/DirectPaymentDrawerContent'
import trackEvent from 'src/trackers'
import { useStoreActions, useStoreState } from 'stores/hooks'
import VoucherInbox from 'containers/domains/Wallet/VoucherInbox'
import PaymentInstruction from 'containers/domains/Wallet/PaymentInstruction'
import PendingStateContent from 'components/domains/Wallet/PendingVaPayment'

//store
import {
  getCheckoutInstruction,
  getPaymentStatus,
} from 'services/payment'
import { sendLog } from 'helpers/log'
import { IProductsProps } from 'services/payment'
import { generateShortUuid } from 'helpers/generate/uuid'
import ErrorPayment from 'components/domains/Payment/ErrorPayment'
import { useRouter } from 'next/router'

import { getIdToken } from 'helpers/auth'
import { useCommunityStore } from 'stores/domains/Social'
import Box from 'components/commons/Box'
import ModalDrawer from 'components/domains/User/ModalDrawer'
import ActionButton from 'components/commons/Button/Action'
import PaymentMethodRadio from 'containers/domains/Wallet/PaymentMethodRadio'
import FeesPaymentDetail from 'components/domains/Wallet/FeesPaymentDetail'
import {
  LabelWrapperInfo,
  StyledInfoIcon,
  StyledInfoLabel,
} from 'containers/domains/TransactionHistory/TransactionCard/styled'
import { useTranslation } from 'react-i18next'
import SuccessVaPaymentContent from 'components/domains/Wallet/SuccessVaPaymentContent'
import redirect from 'helpers/redirect'
import NewtabConfirmation from 'components/domains/Wallet/NewtabConfirmation'
import { ContainerBtn } from 'components/commons/PaymentModal/v2/styled'
import SuccessTopupCoin from 'components/domains/Wallet/SuccessTopupCoin'

function DrawerSkeleton() {
  return (
    <Box>
      <Skeleton
        variant="rectangular"
        height={36}
        width={'100%'}
        sx={{ background: 'lightgrey', borderRadius: '8px' }}
      />
    </Box>
  )
}

interface IDirectPaymentDrawer {
  title?: string
  trackerData?: any
  onInit: Function
  onClose: Function
  onPaymentClose: Function
  onPayNowClick: Function
  onCancelTransaction?: Function
  itemType: string
  useVoucher?: boolean
  trackerCompletedPurchase?: Function
  paymentInstruction?: {
    is_show: boolean
    title: string
    body: string
    invoice_id: string
    payment_method_code: string
  }
  idempotentKey: string
  selectedProducts?: {
    products: Array<IProductsProps>
    payment_method: string
    checkout_source: string
    user_mask_id?: string
    voucher_code?: string
    idempotentKey: string
  }
  bookingId?: string
  setIdempotentKey: Function
  setPaymentType?: Function
  paymentType?: string
  preCheckout?: Function
  setGuestPendingTransaction?: Function
  invoice_id?: string
}

function DirectPaymentModal({
  title,
  trackerData,
  onInit = () => {},
  onClose = () => {},
  onPaymentClose = () => {},
  onPayNowClick = () => {},
  onCancelTransaction = () => {},
  itemType,
  useVoucher = true,
  paymentInstruction,
  idempotentKey,
  selectedProducts,
  bookingId,
  setIdempotentKey,
  setPaymentType,
  paymentType,
  preCheckout,
  setGuestPendingTransaction,
  invoice_id,
}: IDirectPaymentDrawer) {
  const { t } = useTranslation()
  const [isConfirmationDrawerShown, setIsConfirmationDrawerShown] =
    useState(true)
  const [arrayFees, setArrayFees] = useState([])
  const [arrayFeesPromo, setArrayFeesPromo] = useState([])
  const [cartData, setCartData] = useState(null)
  const [cartValues, setCartValues] = useState(null)
  const [checkoutValues, setCheckoutValues] = useState(null)
  const [errorMessageCheckout, setErrorMessageCheckout] = useState('')
  const [isErrorCheckout, setIsErrorCheckout] = useState<
    boolean | any
  >(false)
  const [isErrorCart, setIsErrorCart] = useState<boolean | any>(false)
  const [isErrorVaInstruction, setIsErrorVaInstruction] = useState<
    boolean | any
  >(false)
  const [isErrorVaStatus, setIsErrorVaStatus] = useState<
    boolean | any
  >(false)
  const [isVoucherInbox, setIsVoucherInbox] = useState(false)
  const [isErrorVoucher, setIsErrorVoucher] = useState<boolean | any>(
    false,
  )
  const [paymentInstructionList, setPaymentInstructionList] =
    useState(null)
  const [vaStatus, setVaStatus] = useState(null)
  const [isSuccessStateCoin, setIsSuccessStateCoin] = useState(false)
  const [selectedPaymentType, setSelectedPaymentType] = useState(0)
  const [isAdditionalFeesOpen, setIsAdditionalFeesOpen] =
    useState(false)
  const [isPaymentLoading, setIsPaymentLoading] = useState(false)
  const { isReady, pathname } = useRouter()
  const [voucherData, setVoucherData] = useState(null)
  const [voucherDetails, setVoucherDetails] = useState(null)
  const [isConfirmationPopup, setIsConfirmationPopup] =
    useState(false)

  const {
    setShowTopUpModal,
    setTopUpCoinStatus,
    clearUserAdditionalInfo,
  } = useStoreActions((actions) => ({
    ...actions.topUp,
    ...actions.eventPersonalQuestions,
  }))
  const { topUpCoinStatus } = useStoreState((states) => ({
    ...states.topUp,
  }))
  const { community } = useCommunityStore((state) => ({
    community: state.community,
  }))
  const modalName = () => {
    switch (itemType) {
      case 'content':
        return 'PURCHASE_CONTENT'
      case 'event':
        return 'PURCHASE_ETICKET'
      default:
        return ''
    }
  }

  const addCartHandler = async () => {
    try {
      setIsPaymentLoading(true)
      setCartValues(await onInit())
    } catch (error) {
      setIsErrorCheckout(error)
      setIsErrorCart(error)
      setErrorMessageCheckout(error?.response?.data?.message)
      sendLog(
        `Error: ${error?.response?.data?.code} - ${error?.response?.data?.message}`,
      )
      if (error?.response?.status === 429) {
        handleModalCleaner()
        onClose()
      }
    } finally {
      setIsPaymentLoading(false)
    }
  }

  const checkoutHandler = async (cartValues) => {
    try {
      setIsPaymentLoading(true)
      const checkoutData = await onPayNowClick(
        itemType === 'event' ||
          itemType === 'content' ||
          itemType === 'coin'
          ? !cartData?.disable_voucher_reason
            ? cartValues?.payment_method[selectedPaymentType]
                ?.voucher_code
            : ''
          : cartValues,
      )
      setCheckoutValues(checkoutData)
      paymentHandler(checkoutData)
      setVoucherData(null)
      setCartValues(null)
    } catch (error) {
      setIsErrorCheckout(error)
      setErrorMessageCheckout(error?.response?.data?.message)
      sendLog(
        `Error: ${error?.response?.data?.code} - ${error?.response?.data?.message}`,
      )
      if (error?.response?.status !== 500) {
        setIdempotentKey(generateShortUuid())
      }
      if (error?.response?.status === 429) {
        handleModalCleaner()
        onClose()
      }
    } finally {
      setIsPaymentLoading(false)
    }
  }

  const paymentHandler = (checkoutData) => {
    if (
      itemType === 'event' ||
      itemType === 'content' ||
      itemType === 'coin'
    ) {
      if (cartData?.price?.total_price === 0) {
        return window.location.reload()
      }
      //BCA VA
      else if (
        checkoutData?.invoice_url === '' &&
        checkoutData?.payment_method_code === 'H2H_BCA_VA'
      ) {
        instructionsHandler(
          checkoutData?.invoice_id,
          checkoutData?.payment_method_code,
        )
      } //tiptip coin
      else if (
        checkoutData?.payment_method_code === 'TIPTIP_COIN' &&
        checkoutData?.status?.toUpperCase() === 'COMPLETED'
      ) {
        setIsSuccessStateCoin(true)
      } //default
      else {
        if (checkoutData?.status?.toUpperCase() === 'FAILED') {
          setIsErrorCheckout(true)
        } else {
          handleExternalPayment(checkoutData)
        }
      }
      //default
    } else {
      if (checkoutData?.status?.toUpperCase() === 'FAILED') {
        setIsErrorCheckout(true)
      } else {
        handleExternalPayment(checkoutData)
      }
    }
    //trackers only
    if (itemType === 'content')
      trackEvent.content('purchase_content_completed', trackerData, {
        content_admin_fee: cartData.transaction_fee,
        content_tax_value: cartData.tax,
        content_total_price: cartData?.price?.total_price,
        content_discount: voucherData
          ? cartData?.price?.voucher_discount
          : '',
        voucher_code: cartData?.voucher_code,
      })
    //end of trackers only
    if (
      itemType === 'event' &&
      checkoutData?.status?.toUpperCase() !== 'FAILED'
    ) {
      clearUserAdditionalInfo()
      eventPaymentTracker('purchase_eticket_completed')
    }
  }
  const checkStatusHandler = async (invoiceId, paymentMethod) => {
    try {
      setIsPaymentLoading(true)
      const paymentStatus = await getPaymentStatus({
        invoice_id: invoiceId,
        payment_method: paymentMethod,
        idempotentKey: idempotentKey,
      })
      setVaStatus(paymentStatus)
      if (paymentStatus?.status?.toLowerCase() === 'paid') {
        setIsSuccessStateCoin(true)
        setCheckoutValues(null)
      }
    } catch (error) {
      sendLog(
        `Error: ${error?.response?.status} - ${error?.response?.data?.message}`,
      )
      if (error?.response?.data?.code === 'NOT_FOUND') {
        setIsErrorVaStatus(error)
        setPaymentInstructionList('')
      }
    } finally {
      setIsPaymentLoading(false)
    }
  }

  const instructionsHandler = async (invoiceId, method) => {
    setIsPaymentLoading(true)
    try {
      setPaymentInstructionList(
        await getCheckoutInstruction({
          invoice_id: invoiceId,
          payment_method: method,
          idempotentKey: idempotentKey,
        }),
      )
      setIsPaymentLoading(false)
    } catch (error) {
      setIsErrorVaInstruction(error)
      sendLog(
        `Error: ${error?.response?.status} - ${error?.response?.data?.message}`,
      )
    } finally {
      setIsPaymentLoading(false)
    }
  }
  const cancelTransaction = async () => {
    try {
      setIsPaymentLoading(true)
      await onCancelTransaction(
        isErrorCheckout?.response?.data?.data?.invoice_id,
      )
      onPaymentClose()
    } catch (error) {
      sendLog(
        `Error: ${error?.response?.status} - ${error?.response?.data?.message}`,
      )
    } finally {
      setIsPaymentLoading(false)
    }
  }

  const paymentTypeHandler = () => {
    if (!invoice_id) {
      if (voucherData) {
        setPaymentType(
          voucherData?.payment_method[selectedPaymentType]?.code,
        )
      } else {
        setPaymentType(
          cartValues?.payment_method[selectedPaymentType]?.code,
        )
      }
    }
  }

  const handlePaymentTypeChange = (event) => {
    setSelectedPaymentType(parseInt(event.target.value))
    paymentTypeHandler()
  }

  const handleExternalPayment = (checkoutData) => {
    if (window.open(checkoutData?.invoice_url) === null) {
      setIsConfirmationPopup(true)
    } else {
      setSelectedPaymentType(0)
      if (itemType === 'coin') {
        checkStatusHandler(
          checkoutData?.invoice_id,
          checkoutData?.payment_method_code,
        )
        preCheckout()
      } else {
        modalVaStatusCloser()
      }
    }
  }

  const voucherTracker = (eventName, voucherCode) => {
    return trackEvent.payment(eventName, trackerData, {
      modal_name: 'PURCHASE_CONTENT',
      voucher_code: voucherCode,
    })
  }
  const eventPaymentTracker = (eventName) => {
    return trackEvent.event(eventName, trackerData, {
      modal_name: modalName(),
      user_type: getIdToken() ? 'supporter' : 'guest',
      event_quantity: selectedProducts?.products[0]?.tickets.reduce(
        (sum, obj) => sum + obj.qty,
        0,
      ),
      event_admin_fee: cartData?.transaction_fee,
      event_tax_value: cartData?.tax,
      event_discount: voucherData
        ? cartData?.price?.voucher_discount
        : '',
      event_total_price: totalPriceHandler('total_price'),
      event_subtotal: totalPriceHandler('sub_total'),
      is_member_community: community.is_member || '',
      voucher_code: voucherData
        ? voucherData?.payment_method[selectedPaymentType]
            ?.voucher_code
        : '',
    })
  }

  const handleVoucherClear = () => {
    setVoucherData(null)
    setSelectedPaymentType(0)
    setVoucherDetails(null)
  }
  const handleModalCleaner = () => {
    setIsErrorCart(false)
    setIsErrorVaInstruction(false)
    setIsErrorCheckout(false)
    handleVoucherClear()
    setSelectedPaymentType(0)
    if (itemType === 'coin') {
      trackEvent.payment('cancel_topup_payment', trackerData, {
        modal_name: 'TOPUP_PAYMENT_METHOD',
        trnx_fee: cartData?.transaction_fee,
        total_payment: cartData?.total,
        total_topup_coin: trackerData?.totalCoinTopUp,
      })
    } else {
      setCheckoutValues(null)
    }
  }
  const modalVaStatusCloser = () => {
    setIsConfirmationPopup(false)
    setIsConfirmationDrawerShown(false)
    setSelectedPaymentType(0)
    setCartData(null)
    onPaymentClose()
    handleVoucherClear()
    if (itemType === 'coin') {
      preCheckout()
    } else {
      setCheckoutValues(null)
    }
  }
  useEffect(() => {
    if (topUpCoinStatus === 'finish') {
      addCartHandler()
      setTopUpCoinStatus('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topUpCoinStatus])

  useEffect(() => {
    if (invoice_id) {
      instructionsHandler(invoice_id, 'H2H_BCA_VA')
    } else if (paymentInstruction?.invoice_id) {
      instructionsHandler(
        paymentInstruction?.invoice_id,
        paymentInstruction?.payment_method_code,
      )
    } else {
      addCartHandler()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice_id])

  useEffect(() => {
    if (cartValues) {
      if (itemType === 'session') {
        setCartData(cartValues)
      } else if (itemType === 'subscription') {
        setCartData(cartValues.payment_info)
      } else {
        paymentTypeHandler()
        setCartData(cartValues?.payment_method[selectedPaymentType])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentType, cartValues])

  useEffect(() => {
    if (cartData) {
      if (
        itemType === 'event' ||
        itemType === 'content' ||
        itemType === 'coin'
      ) {
        setArrayFees([
          {
            label: t('paymentDetailWidgetAdditionalFeeLabel'),
            price:
              itemType === 'coin'
                ? cartData?.transaction_fee
                : cartData?.price?.total_additional_fee,
          },
        ])
      } else {
        setArrayFees([
          {
            label:
              itemType === 'session' || itemType === 'subscription'
                ? t('paymentDetailWidgetAdditionalFeeLabel')
                : cartData?.admin_fee_title,
            price:
              itemType === 'subscription'
                ? cartData?.admin_fee
                : cartData?.transaction_fee,
          },
          {
            label: 'PPN',
            price: cartData?.tax,
          },
        ])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData, voucherData, cartValues])

  useEffect(() => {
    if (
      cartData &&
      voucherData?.payment_method[selectedPaymentType]
        ?.voucher_code !== null &&
      arrayFees
    ) {
      setArrayFeesPromo([
        ...arrayFees,
        {
          label: cartData?.voucher_info,
          price: cartData?.price?.voucher_discount,
        },
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData, cartValues, arrayFees])

  useEffect(() => {
    if (voucherData && isReady) {
      setIsVoucherInbox(false)
      setCartData(voucherData?.payment_method[selectedPaymentType])
      if (itemType !== 'subscription' && itemType !== 'session') {
        paymentTypeHandler()
      }
    } else {
      setCartData(cartValues?.payment_method[selectedPaymentType])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherData, selectedPaymentType])

  //check if coin priority
  useEffect(() => {
    const paymentOptions = cartValues
    if (paymentOptions) {
      const coinPayment = paymentOptions?.payment_method?.find(
        (item) => item.code === 'TIPTIP_COIN',
      )
      const coinPaymentIndex =
        paymentOptions?.payment_method?.findIndex(
          (item) => item.code === 'TIPTIP_COIN',
        )

      if (
        coinPayment?.coin?.balance >= coinPayment?.coin?.total_price
      ) {
        setSelectedPaymentType(coinPaymentIndex)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartValues])

  useEffect(() => {
    if (
      voucherData?.payment_method?.length <
      cartValues?.payment_method?.length
    ) {
      setSelectedPaymentType(1)
    } else if (
      voucherData?.payment_method?.length >
      cartValues?.payment_method?.length
    ) {
      setSelectedPaymentType(
        voucherData?.payment_method.findIndex(
          (item) =>
            item.code ===
            cartValues?.payment_method[selectedPaymentType]?.code,
        ),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherData, cartValues])

  const totalPriceHandler = (priceType) => {
    if (itemType === 'event' || itemType === 'content') {
      return cartData?.price?.[priceType]
    } else {
      return cartData?.[priceType]
    }
  }
  const basePriceHandler = () => {
    if (itemType === 'event' || itemType === 'content') {
      return cartData?.price?.sub_total
    } else if (itemType === 'subscription') {
      return cartData?.price
    } else {
      return cartData?.sub_total
    }
  }

  const topUpCoinHandler = () => {
    setTopUpCoinStatus('start')
    setShowTopUpModal(true)
    setIsConfirmationDrawerShown(true)
    trackEvent.payment('click_topup', trackerData, {
      modal_name: modalName(),
    })
  }

  const submitButtonLabel = () => {
    if (isPaymentLoading) {
      return (
        <CircularProgress style={{ width: '18px', height: '18px' }} />
      )
    } else if (
      checkoutValues?.status?.toUpperCase() === 'COMPLETED'
    ) {
      return t('back')
    } else if (
      paymentInstructionList?.invoice_id ||
      paymentInstruction?.invoice_id ||
      vaStatus ||
      invoice_id
    ) {
      if (
        vaStatus?.status?.toLowerCase() === 'pending' &&
        ((itemType === 'coin' &&
          checkoutValues?.payment_method_code === 'H2H_BCA_VA') ||
          (itemType !== 'coin' &&
            checkoutValues?.payment_method_code !== 'OTHER_METHOD'))
      ) {
        return t('back')
      } else {
        return t('vaPaymentInstructionWidgetButton')
      }
    } else {
      if (cartData?.disable_voucher_reason) {
        return t('key_pay_without_promo')
      }

      if (
        voucherData ||
        itemType === 'coin' ||
        itemType === 'session' ||
        itemType === 'subscription'
      ) {
        return t('paymentConfirmationBottomSheetButton')
      } else {
        return t('key_pay_without_promo')
      }
    }
  }

  const submitCartHandler = () => {
    if (itemType === 'content') {
      voucherTracker(
        'purchase_content_confirm',
        voucherData?.payment_method[selectedPaymentType]
          ?.voucher_code,
      )
    }
    if (itemType === 'event') {
      eventPaymentTracker('purchase_eticket_confirm')
    }
    if (itemType === 'coin') {
      trackEvent.payment('topup_completed', trackerData, {
        modal_name: 'TOPUP_PAYMENT_METHOD',
        trnx_fee: cartData?.transaction_fee,
        total_payment: cartData?.total,
        total_topup_coin: trackerData?.totalCoinTopUp,
      })
    }
    checkoutHandler(voucherData ? voucherData : cartValues)
  }

  const buttonSubmitHandler = () => {
    if (cartValues || voucherData) {
      submitCartHandler()
    } else if (
      paymentInstructionList?.invoice_id ||
      paymentInstruction?.invoice_id ||
      checkoutValues?.status?.toLowerCase() === ''
    ) {
      if (
        vaStatus?.status?.toLowerCase() === 'pending' &&
        ((itemType === 'coin' &&
          checkoutValues?.payment_method_code === 'H2H_BCA_VA') ||
          (itemType !== 'coin' &&
            checkoutValues?.payment_method_code !== 'OTHER_METHOD'))
      ) {
        setVaStatus('')
      } else {
        if (itemType === 'coin') {
          checkStatusHandler(
            checkoutValues?.invoice_id,
            checkoutValues?.payment_method_code,
          )
        } else {
          checkStatusHandler(
            paymentInstructionList?.invoice_id
              ? paymentInstructionList?.invoice_id
              : paymentInstruction?.invoice_id,
            paymentInstructionList?.payment_method_code
              ? paymentInstructionList?.payment_method_code
              : paymentInstruction?.payment_method_code,
          )
        }
        handleModalCleaner()
      }
    } else if (
      checkoutValues?.status?.toUpperCase() === 'COMPLETED'
    ) {
      setIsSuccessStateCoin(false)
      modalVaStatusCloser()
    } else {
      setIsConfirmationDrawerShown(false)
      handleModalCleaner()
    }
  }

  const onCloseHandler = () => {
    if (cartValues || voucherData) {
      if (isVoucherInbox && !voucherData) {
        setIsErrorVoucher(false)
        if (voucherDetails) {
          setIsVoucherInbox(true)
          setVoucherDetails(null)
        } else {
          setIsVoucherInbox(false)
          if (!voucherData) {
            handleVoucherClear()
          }
        }
      } else {
        const errorCode =
          isErrorCheckout?.response?.data?.code ||
          isErrorCart?.response?.data?.code ||
          isErrorVaStatus?.response?.data?.code

        const disableClose =
          errorCode === 'BOOKING_EXPIRED' ||
          errorCode === 'CHECKOUT_INVALID_STATUS'
        if (!disableClose) {
          handleModalCleaner()
          onClose()
        }
      }
    } else if (
      ((paymentInstructionList?.invoice_id ||
        paymentInstruction?.invoice_id ||
        invoice_id) &&
        paymentInstructionList) ||
      (vaStatus &&
        (!checkoutValues?.status ||
          checkoutValues?.status?.toLowerCase() === 'pending'))
    ) {
      if (
        vaStatus?.status?.toLowerCase() === 'pending' &&
        (!checkoutValues?.status ||
          checkoutValues?.status?.toLowerCase() === 'pending')
      ) {
        onPaymentClose()
        handleModalCleaner()
      } else if (vaStatus?.status?.toLowerCase() === 'paid') {
        setIsPaymentLoading(true)
        handleModalCleaner()
        modalVaStatusCloser()
      } else {
        setIsConfirmationDrawerShown(false)
        handleModalCleaner()
        onPaymentClose()
        if (itemType === 'coin') {
          preCheckout()
        }
      }
    } else if (
      checkoutValues?.status?.toUpperCase() === 'COMPLETED' &&
      isSuccessStateCoin
    ) {
      setIsPaymentLoading(true)
      handleModalCleaner()
      modalVaStatusCloser()
      setIsSuccessStateCoin(false)
    } else {
      modalVaStatusCloser()
    }
  }

  const onCloseHandlerError = () => {
    handleModalCleaner()
    setCartData(null)
    setCartValues(null)
    setTimeout(
      () =>
        onClose(
          isErrorCheckout?.response?.data?.code ||
            isErrorCart?.response?.data?.code ||
            isErrorVaStatus?.response?.data?.code,
        ),
      200,
    )
  }

  const onRetryHandler = () => {
    setCheckoutValues(null)
    setIsErrorCart(false)
    setIsErrorVaInstruction(false)
    setIsErrorCheckout(false)
    setIsErrorVaStatus(false)
  }

  const onContinueTransaction = () => {
    setIsErrorCart(false)
    setIsErrorVaStatus(false)
    setIsErrorVaInstruction(false)
    setIsErrorCheckout(false)
    if (!getIdToken()) {
      setCheckoutValues({
        invoice_url:
          isErrorCheckout?.response?.data?.data?.invoice_url,
      })
      if (
        isErrorCheckout?.response?.data?.data.payment_method_code ===
        'H2H_BCA_VA'
      ) {
        setCheckoutValues({
          invoice_url:
            isErrorCheckout?.response?.data?.data?.invoice_url,
        })
        setGuestPendingTransaction(
          isErrorCheckout?.response?.data?.data,
        )(true)
      } else handleExternalPayment(checkoutValues)
    } else {
      redirect(
        `/transaction-history?source=${pathname}&source_action=`,
      )
    }
  }

  const onClosePaymentHandler = () => {
    onCloseHandlerError()
    setSelectedPaymentType(0)
  }
  const titleHandler = () => {
    if (isVoucherInbox && !voucherData) {
      if (voucherDetails) {
        return 'Voucher Detail'
      } else if (
        isErrorVoucher?.response?.data?.code === 'BOOKING_EXPIRED' ||
        isErrorVoucher?.response?.data?.code ===
          'CHECKOUT_INVALID_STATUS'
      ) {
        return ''
      } else {
        return 'Voucher Inbox'
      }
    } else if (
      paymentInstructionList?.invoice_id ||
      paymentInstruction?.invoice_id ||
      (invoice_id && !isErrorVaStatus) ||
      checkoutValues?.status?.toUpperCase() === '' ||
      vaStatus
    ) {
      if (
        vaStatus?.status?.toLowerCase() === 'pending' ||
        vaStatus?.status?.toLowerCase() === 'paid' ||
        isConfirmationPopup
      ) {
        return ''
      } else {
        return 'BCA Virtual Account'
      }
    } else if (
      isConfirmationPopup ||
      isErrorCart ||
      isErrorCheckout ||
      isErrorVaInstruction ||
      isErrorVaStatus ||
      checkoutValues?.status?.toUpperCase() === 'COMPLETED'
    ) {
      return ''
    } else {
      return title
    }
  }
  const PreCheckoutContent = () => {
    return (
      <Box>
        <>
          {cartValues?.payment_information &&
            itemType === 'event' && (
              <LabelWrapperInfo>
                <StyledInfoIcon />
                <StyledInfoLabel>
                  {cartValues?.payment_information}
                </StyledInfoLabel>
              </LabelWrapperInfo>
            )}
          {itemType !== 'session' && itemType !== 'subscription' && (
            <PaymentMethodRadio
              isPaymentLoading={isPaymentLoading}
              paymentType={selectedPaymentType}
              cartValues={voucherData || cartValues}
              handlePaymentTypeChange={handlePaymentTypeChange}
              topUpCoinHandler={topUpCoinHandler}
            />
          )}
          <DirectPaymentDrawerContent
            voucherCode={
              voucherData?.payment_method[selectedPaymentType]
                ?.voucher_code
            }
            items={[
              {
                label:
                  itemType === 'subscription' ? (
                    <>
                      {cartValues.title} <br />
                      {Math.floor(cartValues?.duration / 30)} Bulan
                    </>
                  ) : (
                    t('paymentDetailWidgetBasePriceLabel')
                  ),
                price: basePriceHandler(),
              },
            ]}
            fees={voucherData ? arrayFeesPromo : arrayFees}
            totalPrice={totalPriceHandler(
              itemType === 'event' || itemType === 'content'
                ? 'total_price'
                : 'total',
            )}
            totalPriceCoin={cartData?.coin?.total_price}
            subTotalPrice={totalPriceHandler(
              'total_price_before_discount',
            )}
            handleVoucherClear={handleVoucherClear}
            voucherInboxOpen={() => {
              setIsVoucherInbox(true)
            }}
            itemType={itemType}
            useVoucher={useVoucher}
            isEligibleVoucher={
              voucherData
                ? cartData?.disable_voucher_reason
                : (itemType === 'event' || itemType === 'content') &&
                  cartValues?.payment_method[selectedPaymentType]
                    ?.disable_voucher_reason
            }
            isCoinMethod={
              (itemType === 'event' || itemType === 'content') &&
              (voucherData
                ? voucherData.payment_method[selectedPaymentType]
                    ?.code === 'TIPTIP_COIN'
                : cartValues?.payment_method[selectedPaymentType]
                    ?.code === 'TIPTIP_COIN')
            }
            isPaymentLoading={isPaymentLoading}
            setIsAdditionalFeesOpen={setIsAdditionalFeesOpen}
          />
        </>
      </Box>
    )
  }
  const ErrorPaymentContainer = () => {
    return (
      <ErrorPayment
        itemType={itemType}
        onClosePaymentHandler={onClosePaymentHandler}
        onRetryHandler={onRetryHandler}
        onContinueTransaction={onContinueTransaction}
        cancelTransaction={cancelTransaction}
        isPaymentLoading={isPaymentLoading}
        errorMessageCheckout={errorMessageCheckout}
        isErrorVaStatus={isErrorVaStatus}
        isErrorCart={isErrorCart}
        isErrorCheckout={isErrorCheckout}
        isErrorVaInstruction={isErrorVaInstruction}
        isErrorVoucher={isErrorVoucher}
        setShowTopUpModal={topUpCoinHandler}
      />
    )
  }
  const PaymentBodyContent = () => {
    if (cartValues || voucherData) {
      if (isVoucherInbox && !voucherData) {
        return (
          <VoucherInbox
            handleVoucherClear={handleVoucherClear}
            trackerData={trackerData}
            page={itemType === 'content' || itemType === 'event'}
            idempotentKey={idempotentKey}
            selectedProducts={selectedProducts}
            bookingId={bookingId}
            paymentType={paymentType}
            itemType={itemType}
            community={community}
            voucherData={voucherData}
            setVoucherData={setVoucherData}
            voucherDetails={voucherDetails}
            setVoucherDetails={setVoucherDetails}
            setIsErrorVoucher={setIsErrorVoucher}
            isErrorVoucher={isErrorVoucher}
          />
        )
      } else {
        return (
          //precheckout
          <>
            <PreCheckoutContent />
            <ActionButton
              id={`b-wallet-${itemType}-buy-now`}
              sx={{
                padding: '12px 24px',
                width: '100%',
              }}
              disabled={isPaymentLoading}
              onClick={() => {
                buttonSubmitHandler()
              }}
            >
              {submitButtonLabel()}
            </ActionButton>
          </>
        )
      }
    } else if (
      ((paymentInstructionList?.invoice_id ||
        paymentInstruction?.invoice_id ||
        invoice_id) &&
        paymentInstructionList) ||
      (vaStatus &&
        (!checkoutValues?.status ||
          checkoutValues?.status?.toLowerCase() === 'pending'))
    ) {
      if (
        vaStatus?.status?.toLowerCase() === 'pending' &&
        (!checkoutValues?.status ||
          checkoutValues?.status?.toLowerCase() === 'pending')
      ) {
        return (
          <>
            <PendingStateContent
              vaStatus={vaStatus}
              itemType={itemType}
            />
            <ActionButton
              sx={{
                padding: '12px 24px',
                width: '100%',
              }}
              disabled={isPaymentLoading}
              onClick={() => {
                buttonSubmitHandler()
              }}
            >
              {submitButtonLabel()}
            </ActionButton>
          </>
        )
      } else if (vaStatus?.status?.toLowerCase() === 'paid') {
        if (itemType === 'coin') {
          return (
            <SuccessTopupCoin
              topUpCoinStatus={topUpCoinStatus}
              coin_info={vaStatus.coin_info}
              handleClose={modalVaStatusCloser}
            />
          )
        } else {
          return (
            <SuccessVaPaymentContent
              vaStatus={vaStatus}
              itemType="event"
              handleClose={modalVaStatusCloser}
              isPaymentLoading={isPaymentLoading}
            />
          )
        }
      } else {
        return (
          <>
            <PaymentInstruction
              paymentInstructionList={paymentInstructionList}
            />
            <ActionButton
              sx={{
                padding: '12px 24px',
                width: '100%',
              }}
              disabled={isPaymentLoading}
              onClick={() => {
                buttonSubmitHandler()
              }}
            >
              {submitButtonLabel()}
            </ActionButton>
          </>
        )
      }
    } else if (
      (vaStatus?.status?.toLowerCase() === 'paid' ||
        checkoutValues?.status?.toUpperCase() === 'COMPLETED') &&
      isSuccessStateCoin
    ) {
      return (
        <SuccessVaPaymentContent
          vaStatus={
            vaStatus
              ? vaStatus
              : {
                  invoice_id: checkoutValues?.invoice_id,
                  payment_method_code:
                    checkoutValues?.payment_method_code,
                  status: checkoutValues?.status,
                  paid_at: checkoutValues?.paid_at,
                  checkout_at: checkoutValues?.paid_at,
                  payment_method_name:
                    checkoutValues?.payment_method_code,
                  total: checkoutValues?.coin_info?.total_purchase,
                  ticket: '',
                }
          }
          itemType="event"
          handleClose={modalVaStatusCloser}
          isPaymentLoading={isPaymentLoading}
        />
      )
    } else if (isConfirmationPopup) {
      return (
        <>
          <NewtabConfirmation />
          <ContainerBtn gap="16px">
            <ActionButton
              sx={{
                padding: '12px 24px',
                width: '100%',
              }}
              buttonVariant="outlined"
              disabled={isPaymentLoading}
              onClick={() => {
                modalVaStatusCloser()
              }}
            >
              {t('back')}
            </ActionButton>
            <ActionButton
              sx={{
                padding: '12px 24px',
                width: '100%',
              }}
              disabled={isPaymentLoading}
              onClick={() => {
                window.open(checkoutValues.invoice_url, '_blank')
                if (itemType === 'coin') {
                  checkStatusHandler(
                    checkoutValues?.invoice_id,
                    checkoutValues?.payment_method_code,
                  )
                  preCheckout()
                } else {
                  modalVaStatusCloser()
                }
              }}
            >
              {t('continueText')}
            </ActionButton>
          </ContainerBtn>
        </>
      )
    } else {
      return <DrawerSkeleton />
    }
  }

  const errorDisplayVoucher =
    isErrorVoucher &&
    ((isErrorVoucher?.response?.status !== 429 &&
      isErrorVoucher?.response?.data?.code === 'BOOKING_EXPIRED') ||
      (isErrorVoucher?.response?.status !== 429 &&
        isErrorVoucher?.response?.data?.code ===
          'CHECKOUT_INVALID_STATUS'))

  return (
    <>
      {cartData?.price?.additional_fee && (
        <FeesPaymentDetail
          paymentMethod={cartData}
          isAdditionalFeesOpen={isAdditionalFeesOpen}
          setIsAdditionalFeesOpen={setIsAdditionalFeesOpen}
        />
      )}
      <ModalDrawer
        title={titleHandler()}
        isOpen={isConfirmationDrawerShown}
        onClose={onCloseHandler}
        sxDrawerContainer={{ padding: '16px 0px' }}
        renderTitle
      >
        {isErrorCart ||
        isErrorCheckout ||
        isErrorVaInstruction ||
        isErrorVaStatus ||
        errorDisplayVoucher ? (
          (isErrorCart?.response?.status !== 429 ||
            isErrorCheckout?.response?.status !== 429 ||
            isErrorVaInstruction?.response?.status !== 429 ||
            isErrorVaStatus?.response?.status !== 429) && (
            <ErrorPaymentContainer />
          )
        ) : (
          <PaymentBodyContent />
        )}
      </ModalDrawer>
    </>
  )
}

export default DirectPaymentModal
