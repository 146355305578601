import { sendLog } from 'helpers/log'

export const checkNavigatorUserMedia = (callback: Function): void => {
  if (navigator.mediaDevices === undefined) {
    // @ts-ignore
    navigator.mediaDevices = {}
  }
  if (navigator?.mediaDevices?.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function (constraints) {
      var getUserMedia =
        // @ts-ignore
        navigator?.webkitGetUserMedia ||
        // @ts-ignore
        navigator?.mozGetUserMedia ||
        // @ts-ignore
        navigator?.msGetUserMedia
      if (!getUserMedia) {
        return Promise.reject(
          new Error(
            'getUserMedia is not implemented in this browser',
          ),
        )
      }
      return new Promise(function (resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject)
      })
    }
  }
  try {
    if (navigator.mediaDevices.getUserMedia) {
      callback()
    } else {
      sendLog('getUserMedia not supported on your browser!')
    }
  } catch (error) {
    sendLog(`ERROR(${error?.code}): ${error?.message}`)
  }
}

export const getLatitudeLongitude = (): Promise<object> => {
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  }

  return new Promise(function (resolve) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const crd = position.coords
        resolve({
          latitude: crd.latitude,
          longitude: crd.longitude,
        })
      },
      (err) => {
        resolve({
          latitude: null,
          longitude: null,
        })
        sendLog(`ERROR(${err.code}): ${err.message}`)
      },
      options,
    )
  })
}
