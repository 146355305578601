import {
  IRequestOTPPayload,
  IVerifyOTPPayload,
} from 'stores/domains/User/Auth/interface'

import { getIdToken } from 'helpers/auth'
import apiClient from 'helpers/api-client'
import { getSessionStorage } from 'helpers/session-storage'
import isEmpty from 'helpers/is-empty'

export const requestOtpForPhone = async (
  payload: IRequestOTPPayload,
) => {
  const token = getIdToken()
  return await apiClient({
    url: isEmpty(token)
      ? `/authentication/guest/v1/phone/otp/send`
      : `/authentication/v1/phone/otp/send`,
    method: 'POST',
    data: payload,
    token,
  })
}

export const requestOtpForEmail = async (
  payload: IRequestOTPPayload,
) => {
  const token = getIdToken()
  return await apiClient({
    url: isEmpty(token)
      ? `/authentication/guest/v1/otp/send`
      : `/authentication/v1/otp/send`,
    method: 'POST',
    data: payload,
    token,
  })
}

export const verifyOtpForPhone = async (
  payload: IVerifyOTPPayload,
) => {
  const token = getIdToken()
  return await apiClient({
    url: isEmpty(token)
      ? `/authentication/guest/v1/phone/otp/verify`
      : `/authentication/v1/phone/otp/verify`,
    method: 'POST',
    data: {
      ...payload,
      otp: Number(payload.otp),
      ip: getSessionStorage('ip'),
    },
    token,
  })
}

export const verifyOtpForEmail = async (
  payload: IVerifyOTPPayload,
) => {
  const token = getIdToken()
  return await apiClient({
    url: isEmpty(token)
      ? `/authentication/guest/v1/otp/verify`
      : `/authentication/v1/otp/verify`,
    method: 'POST',
    data: {
      ...payload,
      otp: Number(payload.otp),
    },
    token,
  })
}
